<template>
  <div id="report-website-activity">
    <v-card>
      <v-card-title>Website activity</v-card-title>

      <v-row class="px-2 ma-0">
        <v-col v-if="isAdmin" cols="12" sm="4">
          <v-autocomplete
            class="chargeback-report-merchant-filter"
            v-model="merchantFilter"
            :items="merchants"
            item-value="companyId"
            item-text="accountName"
            dense
            filled
            outlined
            clearable
            hide-details
            label="Merchant name"
            multiple
            chips
            small-chips
          ></v-autocomplete>
        </v-col>
        <v-col cols="12" sm="4">
          <v-autocomplete
            class="chargeback-report-website-filter"
            v-model="websiteFilter"
            :items="formattedWebsites"
            item-value="id"
            item-text="name"
            dense
            filled
            outlined
            clearable
            hide-details
            label="Website name"
            multiple
            chips
            small-chips
          ></v-autocomplete>
        </v-col>
        <v-col cols="12" sm="4">
          <v-select
            class="chargeback-report-service-filter"
            v-model="serviceFilter"
            placeholder="Select Service"
            :items="serviceOptions"
            item-text="title"
            item-value="value"
            outlined
            dense
            clearable
            hide-details
          ></v-select>
        </v-col>
      </v-row>
      <v-data-table
        :headers="tableColumns"
        v-model="selectedRows"
        :item-class="chargebackReportItemClass"
        :loading="loading"
        :options.sync="options"
        :items="reportWebsiteActivityTable"
        :server-items-length="totalReportWebsiteActivityTable"
        :footer-props="{ 'items-per-page-options': [50, 75, 100] }"
      >
        <template v-slot:item="{ item }">
          <tr>
            <td>{{ item.websiteName }}</td>
            <td v-for="month in last12Months" :key="month">
              {{ item.eventsAmount[month] || 0 }}
            </td>
          </tr>
        </template>
        <template slot="body.append">
          <tr class="total-row">
            <td>Total</td>
            <td v-for="month in last12Months" :key="month">
              {{ totalEvents[month] || 0 }}
            </td>
          </tr>
        </template>
      </v-data-table>
    </v-card>
  </div>
</template>

<script>
import useChargebackReportsWebsiteActivity from '@/views/chargebacks/chargeback-reports/useChargebackReportsWebsiteActivity';
export default {
  name: 'ChargebackReportsWebsiteActivity',
  setup() {
    const {
      isAdmin,
      merchantFilter,
      merchants,
      websites,
      websiteFilter,
      serviceOptions,
      serviceFilter,
      tableColumns,
      loading,
      options,
      reportWebsiteActivityTable,
      totalReportWebsiteActivityTable,
      totalEvents,
      selectedRows,
      last12Months,
    } = useChargebackReportsWebsiteActivity();

    const chargebackReportItemClass = () => {
      return 'chargeback-report-website-activity-item';
    };

    return {
      isAdmin,
      merchantFilter,
      merchants,
      websites,
      websiteFilter,
      serviceOptions,
      serviceFilter,
      chargebackReportItemClass,
      tableColumns,
      loading,
      options,
      reportWebsiteActivityTable,
      totalReportWebsiteActivityTable,
      totalEvents,
      selectedRows,
      last12Months,
    };
  },
  computed: {
    formattedWebsites() {
      return this.websites.map((website) => {
        if (website.status === 'inactive') {
          return { ...website, name: website.name + ' (inactive)' };
        }
        return website;
      });
    },
  },
};
</script>

<style lang="scss">
.theme--dark {
  .total-row {
    background-color: rgb(75 71 100);
  }
}
.total-row td{
  font-size: 14px!important;
}

.theme--light {
  .total-row {
    background-color: rgb(232 231 234);
  }
}

.report-table {
  td {
    width: 155px;
  }
}

.period-date-picker {
  > .v-card__actions {
    display: block !important;
    > div.row:last-child {
      text-align: end;
    }
  }
}

.report-website-activity-table .v-data-table-header:nth-child(2) .v-icon {
  visibility: collapse;
}
</style>
