import store from '@/store';
import { ref, watch } from '@vue/composition-api';
import Vue from 'vue';
import eventServiceEnum from '@/enums/event-service.enum';
import { getProfile } from '@/services/jwt.service';
import Roles from '@/enums/roles.enum';
import moment from 'moment';
import RolesEnum from '@/enums/roles.enum';

export default function useChargebackReportsWebsiteActivity() {
  const isAdmin = getProfile().role === Roles.ADMIN;
  const websites = ref([]);
  const websiteFilter = ref(null);
  const merchants = ref([]);
  const merchantFilter = ref(null);
  const serviceFilter = ref(null);
  const selectedRows = ref([]);
  const reportWebsiteActivityTable = ref([]);
  const totalReportWebsiteActivityTable = ref(null);
  const serviceOptions = eventServiceEnum;
  const loading = ref(false);
  const options = ref({
    sortBy: [moment().format('YYYY-MM')],
    sortDesc: [true],
    page: 1,
    itemsPerPage: 50,
    value: {
      sortBy: [moment().format('YYYY-MM')],
      sortDesc: [true],
      page: '1',
      itemsPerPage: '50'
    }
  });
  const totalEvents =  ref([]);
  let tableColumns = [
    { text: 'Website', value: 'website', width: '200px' },
  ];

  let last12Months = [];

  for (let i = 0; i < 12; i++) {
    tableColumns.push({
      text: i === 0 ? 'Current Month' : moment().subtract(i, 'months').format('MMMM YYYY'),
      value: moment().subtract(i, 'months').format('YYYY-MM'),
      width: '150px',
    });
    last12Months.push(moment().subtract(i, 'months').format('YYYY-MM'));
  }

  const fetchMerchants = () => {
    store
      .dispatch('user/fetchAll', {
        page: 1,
        role: RolesEnum.MERCHANT,
        itemsPerPage: '-1',
      })
      .then(response => {
        merchants.value = response.data.data;
      })
      .catch(() => {
        Vue.notify({
          type: 'error',
          title: 'Merchants',
          text: 'Cannot fetch merchants',
        });
      });
  };

  const fetchWebsites = () => {
    store
      .dispatch('website/fetchWebsites')
      .then(response => {
        websites.value = response.data;
      })
      .catch(() => {
        Vue.notify({
          type: 'error',
          title: 'Websites',
          text: 'Cannot fetch websites',
        });
      });
  };

  const fetchWebsiteActivityReport = () => {
    const filters = {
      service: serviceFilter.value,
      websiteIds: websiteFilter.value,
      companyIds: merchantFilter.value,
      sortBy: options.value.sortBy[0],
      sortDesc: options.value.sortDesc[0],
      page: options.value.page,
      itemsPerPage: options.value.itemsPerPage,
    };

    store
      .dispatch('chargebackReport/fetchWebsiteActivity', {
        ...filters,
      })
      .then(response => {
        reportWebsiteActivityTable.value = response.data.data;
        totalReportWebsiteActivityTable.value = response.data.totalCount;
        totalEvents.value = response.data.totalAmount;

        loading.value = false;
      })
      .catch(() => {
        Vue.notify({
          type: 'error',
          title: 'Events',
          text: 'Cannot fetch website activity report',
        });
      });
  };

  watch([options, merchantFilter, websiteFilter, serviceFilter], () => {
    loading.value = true;
    selectedRows.value = [];
    fetchWebsiteActivityReport();
  });

  if (isAdmin) {
    fetchMerchants();
  }

  fetchWebsites();
  fetchWebsiteActivityReport();

  return {
    isAdmin,
    merchantFilter,
    websiteFilter,
    serviceFilter,
    merchants,
    websites,
    serviceOptions,
    tableColumns,
    loading,
    options,
    reportWebsiteActivityTable,
    totalReportWebsiteActivityTable,
    totalEvents,
    selectedRows,
    last12Months,
  };
}
